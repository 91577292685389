.rbc-calendar {
  height: 612px;
}

.rbc-month-view {
  border: none!important;
}

.rbc-month-header {
}

.rbc-month-row {
  display: flex;
  flex-direction: column;
  border-left: solid 1px #f5f6fa;
  /* border-right: solid 1px #f5f6fa; */
  border-bottom: solid 1px #f5f6fa;
  border-top: 0px!important;
}

.rbc-row-content {
  width: 100%;
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
}

/* .rbc-row-content > .rbc-row > .rbc-date-cell {
  position: absolute;
  bottom: 0;
  right: 0;
} */

.rbc-row-content:first-child {
  width: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.rbc-header {
  /* width: 100px; */
  /* height: 70px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #f5f6fa;
}

.rbc-header a:hover {
  text-decoration: none!important;
}

.rbc-header a {
  width: 100%;
}

.rbc-header span {
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.92px;
  text-align: center;
  color: #7e8a95;
}

.rbc-row-content .rbc-row:first-child {
  order: 12;
  margin-top: auto;
}

.rbc-row-bg {
}

.rbc-day-bg {
  /* width: 118px !important;
  height: 118px !important; */
  border: none!important;
  border-right: 1px solid #f5f6fa!important;
}

.rbc-day-bg:last-child{
  border: none;
}

.rbc-date-cell {
  margin-bottom: 2px;
}

.rbc-date-cell a {
  width: 28px;
  height: 28px;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.56;
  letter-spacing: normal;
  text-align: center;
  color: #505d70;
}

.rbc-date-cell a:hover {
  text-decoration: none;
}

.rbc-allday-cell {
  display: none;
}

.rbc-today {
  background-color: white;
}

.rbc-current.rbc-now a {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 24px;
  height: 24px;
  color: white;
  background-color: var(--background-blue);
  border-radius: 14px;
  font-weight: normal;
}


.rbc-time-slot span {
  font-size: 18px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #7e8a95;
}

.rbc-event {
  background: white;
  padding: 0;
  margin: 0;
  left: 0!important;
  width: 100%!important;
  height: 26px!important;
  overflow-block: hidden;

}

.rbc-header + .rbc-header {
  border-left: none;
}

.rbc-show-more {
  height: 26px;
  border-radius: 4px;
  background-color: #f5f6fa;
  color: #7e8a95;
  font-size: 13px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.08;
  letter-spacing: normal;
  display: flex;
  align-items: center;
  padding-left: 4px;
}

.rbc-off-range-bg {
  background: white;
}
/* WEEK */

.rbc-time-view {
  /* border: none; */
  border: 1px solid #f5f6fa;
  border-left: 0px;
}

.rbc-time-header-gutter {
  max-width: 100px;
  width: 100px;
  border-left: 1px solid #f5f6fa;
  /* border-bottom: 1px solid #f5f6fa; */
}

.rbc-time-gutter .rbc-time-column .rbc-timeslot-group {
  border-left: 0px!important;
}

.rbc-time-header {
  border: none;
}

.rbc-time-column {
  width: 100px;
}

.rbc-time-column .rbc-timeslot-group {
  border: none;
}

.rbc-day-slot {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.rbc-timeslot-group {
  /* max-width: 100px;
  max-height: 70px; */
  border-top: solid 1px #f5f6fa !important;
  border-left: solid 1px #f5f6fa !important;
  border-bottom: 0px;
}

.rbc-time-slot {
  min-height: 70px !important;
  border-top: 0px;
  border: none !important;
}

.rbc-time-header-cell .rbc-header {
  padding: 0px;
  border-left: 1px solid #f5f6fa;
  border-bottom: 0px;
}

.rbc-time-header .rbc-overflowing {
  height: 70px !important;
}

.rbc-time-header-content {
  height: 70px !important;
}

.rbc-row .rbc-time-header-cell {
  height: 70px !important;
}

.rbc-events-container {
  /* max-width: 100px;  */
  min-width: 100% !important;
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.rbc-day-slot .rbc-events-container {
  margin: 0px;
}

.rbc-label {
  font-size: 16px !important;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.61;
  letter-spacing: normal;
  color: #7e8a95;
}

.rbc-time-column .rbc-timeslot-group .rbc-time-slot {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rbc-time-content {
  border: none;
}

.rbc-time-header-content {
  border: none;
}

.rbc-time-header.rbc-overflowing {
  border: none;
}

.rbc-overflowing {
  border: none;
}

.rbc-event-label {
  display: none !important;
}

.rbc-event-content {
  height: 26px!important;
}

.rbc-day-slot .rbc-event {
  border: none;
}

.rbc-current-time-indicator {
  display: none;
}

/* .rbc-time-content:last-child {
  background-color: red;
}

.rbc-time-content:nth-last-child(0) {
  background: red;
} */

.rbc-time-column:nth-child(7) .rbc-timeslot-group {
  opacity: 0.25;
  background-color: #e6e6e9;
}

.rbc-time-column:nth-child(8) {
  opacity: 0.25;
  background-color: #e6e6e9;
}

/* .rbc-time-content:nth-last-child(2) {
  background: red;
} */

